import tracking from '../lib/tracking';

export default function() {
    $(function() {
        if ($('#cookie-notifications-btn').length) {
            $('#cookie-notifications-btn').on('click', function () {
                if ($(this).attr('data-track')) {
                    tracking.ckEventUrl($(this).attr('data-track'));
                }

                let time = 525600 * 60000, // one year in miliseconds
                    vdate = new Date();

                vdate.setTime(vdate.getTime() + time);

                let key = $(this).attr('data-key');
                let cookieName = key ? 'cookie_notification_accepted_' + key : 'fc_cookie_notification_accepted';
                document.cookie = cookieName + '=true; expires=' + vdate + '; path=/';

                $('.cookie-notifications').hide();
                $('body').removeClass('euro-message-active');
            });
        }
    });
}